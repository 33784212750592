import Graph from 'graphology';
import { hasCycle } from 'graphology-dag';

export default function dagStructure(rawEdges: string[][]) {

  const graph = new Graph({
    multi: false,
    allowSelfLoops: false,
    type: 'directed'
  })

  rawEdges.forEach((edge) => {
    graph.updateNode(edge[0])
    graph.updateNode(edge[1])
    graph.updateEdge(edge[0], edge[1])

  })

  if (hasCycle(graph)) {
    return null
  }


  // === Order Nodes into columns

  let temp: any = {}

  graph.forEachNode(nodeId => temp[nodeId] = { to: [], order: 1 })
  graph.forEachEdge((edgeId, attrs, from, to) => {

    // Update reference to downstream nodes.
    temp[from] = { ...temp[from], to: [...temp[from].to, to] }

    // Propagate updates in all downstream nodes
    const recursivelyUpdateDownstreamOrder = (parentOrder: number, nodeId: string) => {
      temp[nodeId] = temp[nodeId] || { to: [] }
      temp[nodeId].order = Math.max(parentOrder + 1, temp[nodeId].order || 0)
      temp[nodeId].to.forEach((id: string) => recursivelyUpdateDownstreamOrder(temp[nodeId].order, id))
    }

    temp[from].to.forEach((id: string) => recursivelyUpdateDownstreamOrder(temp[from].order, id))

  })

  // Align floating nodes towards their target (rightwards)
  Object.keys(temp).forEach(nodeId => {
    const node = temp[nodeId]
    // Nodes without any outgoing edges can stay in first column
    // Only consider moving up nodes with forward targets
    if (node.to.length) {
      const targetsOrders = node.to.map((id: string) => temp[id].order)
      const leftmostTarget = Math.min(...targetsOrders)
      temp[nodeId].order = leftmostTarget - 1
    }
  })

  // Set count of columns in graph as attribute on graph
  graph.setAttribute('columns', Math.max(...Object.values(temp).map((o: any) => o.order)));

  // Set column attribute on nodes, reverse from through calculation
  graph.forEachNode(nodeId => {
    graph.mergeNode(nodeId, {
      column: temp[nodeId].order
    });

  })

  return graph
}