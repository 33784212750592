import { useEffect, useState } from "react";
import { Box, Flex, Stack, Button, Text } from "@chakra-ui/react"
import DependistGraph from "./DependistGraph";
// import Textarea from './Textarea';
import Viewer from "./Viewer";
import MirrorText from "./MirrorText";
import dagStructure from "./engine/dagStructure";
import rawParseText from "./engine/rawParseText";
import forceSimulate from "./engine/forceSimulate";
import addPipesToGraph from "./engine/addPipesToGraph";
import computeGraphState from "./engine/computeGraphState";
import { DirectedGraph } from "graphology";


export default function Dependist(props: { height: number, width: number }) {

    const {
        height,
        width
    } = props

    const headerHeight = 0
    const footerHeight = 0
    const leftWidth = 320
    const viewHeight = height - headerHeight - footerHeight
    const viewWidth = Math.max(width, 800) - leftWidth
    const scrollPadding = 1000


    const [initiated, setInitiated] = useState(false)
    const [loadExampleData, setLoadExampleData] = useState(false)
    const [selectedId, _setSelectedId] = useState('')
    const [zoom, setZoom] = useState(100)
    const [graph, setGraph] = useState<DirectedGraph | null>(null)
    const [inactiveIds, setInactiveIds] = useState<string[]>([])
    const [graphState, setGraphState] = useState({})

    const setSelectedId = (id: string) => {
        _setSelectedId(id)
        setInactiveIds([])
    }

    const reparse = (_input: string) => {
        const rawEdges = rawParseText(_input)
        const graph = dagStructure(rawEdges)
        if (!graph) return
        addPipesToGraph(graph)
        forceSimulate(graph)
        setGraph(graph)
        setSelectedId('')
        setInactiveIds([])
        setGraphState({})
    }

    useEffect(() => {
        if (!graph) return
        const state = computeGraphState(graph, selectedId, inactiveIds)
        setGraphState(state)
    }, [graph, selectedId, inactiveIds])

    // const backparse = () => {
    //     let ret = ''
    //     nodes.forEach(node => {
    //         if (node.id !== node.name) {
    //             ret += `node ${node.id} as ${node.name}\n`
    //         }
    //     })
    //     ret += '\n'
    //     edges.forEach(edge => {
    //         ret += `${edge.from}-->${edge.to}\n`
    //     })
    //     setBackparsed(ret)
    // }

    function toggleNode(nodeId: string) {

        if (inactiveIds.includes(nodeId)) {
            const remainingIds = inactiveIds.filter(id => id !== nodeId)
            setInactiveIds(remainingIds)
        }
        else {
            setInactiveIds([...inactiveIds, nodeId])
        }
    }

    // const handleClickCanvas = () => {
    //     setSelectedId(null)
    // }

    return <Flex>

        <Flex
            width={`${leftWidth}px`}
            flexDirection="column"
        >
            <Flex height={`${headerHeight}px`}></Flex>

            <Stack
                spacing="0"
                borderRight="1px solid"
                borderColor="gray.300"
                w={`${leftWidth}px`}
                position="relative"
            >

                <Stack
                    height={`${100}px`}
                    borderBottom="1px solid"
                    borderColor="gray.300"
                    justifyContent={"flex-start"}
                    alignItems="flex-start"
                >
                    <Text
                        m="4"
                        p="2"
                        // fontFamily={"monospace"}
                        fontSize="16px"
                        fontWeight={"700"}
                        bg="teal.100"
                        letterSpacing={"3px"}
                    >
                        DEPS.LIVE
                    </Text>
                </Stack>

                <MirrorText
                    reparse={reparse}
                    height={`${viewHeight - 100}px`}
                    initiated={initiated}
                    setInitiated={setInitiated}
                    loadExampleData={loadExampleData}
                />

                {!initiated ? (
                    <Stack
                        position="absolute"
                        w={`${leftWidth}px`}
                        alignItems="center"
                        top={`${100 + 100}px`}
                    >

                        <Button
                            size="lg"
                            // variant="outline"
                            // colorScheme="teal"
                            onClick={() => setLoadExampleData(true)}
                        >Load example data</Button>

                    </Stack>

                ) : null}

                {/* <Stack
                    width={`${100}px`}
                >
                    <Button onClick={backparse}>
                        <Text>Backparse</Text>
                    </Button>

                    <Text fontSize="xs"
                        whiteSpace={"break-spaces"}>
                        {backparsed}
                    </Text>


                </Stack> */}

            </Stack>

            <Flex height={`${footerHeight}px`}></Flex>

        </Flex>


        <Flex
            flexDirection="column"
        >
            <Flex height={`${headerHeight}px`}></Flex>

            <Flex>


                <Box
                    position="relative"
                    width={`${viewWidth}px`}
                    height={`${viewHeight}px`}
                // border="1px solid green"
                // onClick={handleClickCanvas}
                >

                    <Viewer
                        height={viewHeight}
                        width={viewWidth}
                        scrollPadding={scrollPadding}
                        zoom={zoom}
                        setZoom={setZoom}
                        setSelectedId={setSelectedId}
                        selectedId={selectedId}
                    >
                        {!graph ? null : (
                            <DependistGraph
                                graph={graph}
                                graphState={graphState}
                                toggleNode={toggleNode}
                                selectedId={selectedId}
                                setSelectedId={setSelectedId}
                                viewHeight={viewHeight}
                                zoom={zoom}
                            />
                        )}

                    </Viewer>


                </Box>

            </Flex>

            <Flex height={`${footerHeight}px`}></Flex>

        </Flex>

    </Flex>

}
