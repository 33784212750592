import Graph from 'graphology';

export default function computeGraphState(graph: Graph, selectedId: string, inactiveIds: string[]) {

  const state: any = {}

  if (!selectedId) {
    return state
  }


  // Explicitly declare state for selected node
  state[selectedId] = { isSelected: true }
  if (inactiveIds.includes(selectedId)) {
    state[selectedId].isInactive = true
    state[selectedId].isBroken = true
  }


  const traverseTowardsRoots = (nodeId: string, outId: string, trail: string[]) => {

    // Abort traversal if edge allready visited
    const edgeId = graph.edge(nodeId, outId) || ''
    if (state.hasOwnProperty(edgeId)) {
      return
    }

    // Instantiate state for current edge and node
    state[edgeId] = { isTrunk: true, isRelevant: true }
    state[nodeId] = state[nodeId] ? state[nodeId] : { isTrunk: true }

    // Assert any inactive state, and - in that case -
    // fold back broken state over nodes in trail.
    if (inactiveIds.includes(nodeId)) {
      state[nodeId].isInactive = true
      state[nodeId].isBroken = true
      // state[edgeId].isInactive = true
      trail.forEach(id => state[id].isBroken = true)
    }

    // Continue traversal towards root
    graph.forEachInNeighbor(nodeId, inId => {
      const attrs = graph.getNodeAttributes(inId)
      if (!attrs.pipe) {
        traverseTowardsRoots(inId, nodeId, [...trail, nodeId, edgeId])
      }
    })

  }


  graph.forEachInNeighbor(selectedId, inId => {
    const pipe = graph.getNodeAttribute(inId, 'pipe')
    if (!pipe) traverseTowardsRoots(inId, selectedId, [selectedId])
  })


  const traverseTowardsLeaves = (nodeId: string, inId: string, isBroken: boolean) => {

    // Abort traversal if edge allready visited
    const edgeId = graph.edge(inId, nodeId) || ''
    // if (state.hasOwnProperty(edgeId)) {
    //   return
    // }

    // Instantiate state for current edge and node
    state[edgeId] = { isBranch: true, isRelevant: true }
    state[nodeId] = state[nodeId] ? state[nodeId] : { isBranch: true }

    // Assert any inactive or broken state
    // const hereBroken = isBroken || inactiveIds.includes(nodeId)
    if (inactiveIds.includes(nodeId)) {
      state[nodeId].isInactive = true
      state[nodeId].isBroken = true
      // state[edgeId].isInactive = true
    }
    if (isBroken || state[nodeId].isInactive) {
      state[nodeId].isBroken = true
      // state[edgeId].isBroken = true
    }

    // Continue traversal towards leaves
    graph.forEachOutNeighbor(nodeId, outId => {
      const attrs = graph.getNodeAttributes(outId)
      if (!attrs.pipe) {
        traverseTowardsLeaves(outId, nodeId, state[nodeId].isInactive || state[nodeId].isBroken)
      }
    })

  }


  graph.forEachOutNeighbor(selectedId, outId => {
    const pipe = graph.getNodeAttribute(outId, 'pipe')
    if (!pipe) traverseTowardsLeaves(outId, selectedId, state[selectedId].isInactive || state[selectedId].isBroken)
  })

  return state

}
