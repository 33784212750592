// @ts-nocheck

import Graph from 'graphology';
import * as d3 from "d3";

const EDGE_SLOT_HEIGHT = 8
const NODE_SLOT_HEIGHT = 26
const SLOT_WIDTH = 180
const HORIZONTAL_SPACING = 90
const CURVE_SHARPNESS = 44


const getRadius = (size: number) => {
    if (size > NODE_SLOT_HEIGHT) {
        return NODE_SLOT_HEIGHT + ((size - NODE_SLOT_HEIGHT) * 0.5)
    }
    else {
        return size
    }
}


export default function forceSimulate(graph: Graph) {


    // graph.forEachNode(nodeId => {
    //     console.log('x', nodeId, graph.getNodeAttributes(nodeId))
    // })

    // graph.forEachEdge(edgeId => {
    //     console.log('z', edgeId, graph.getEdgeAttributes(edgeId))
    // })


    //   nodes: graph.nodes.map(n => ({id: n.key, ...n}) ),
    //   links: graph.edges

    const nodes = graph.mapNodes(nodeId => {
        const attrs = graph.getNodeAttributes(nodeId)
        const leftOffset = (SLOT_WIDTH / 2) + (SLOT_WIDTH * (attrs.column - 1))

        let size

        if (attrs.pipe) {
            size = EDGE_SLOT_HEIGHT
        }
        else {
            size = NODE_SLOT_HEIGHT
        }

        return {
            id: nodeId,
            ...attrs,
            width: SLOT_WIDTH,
            leftOffset: leftOffset,
            fx: HORIZONTAL_SPACING * (attrs.column - 1),
            size: size,
            radius: getRadius(size),
        }
    })

    let links = []
    graph.forEachEdge((edgeId, attrs, source, target) => {
        if (attrs.hasPipes !== true) {
            links.push({
                id: edgeId,
                ...attrs,
                source: source,
                target: target,
            })
        }
    })


    const simulation = d3
        .forceSimulation(nodes)
        .force("charge", d3.forceManyBody())
        .force("link", d3.forceLink())
        .force("center", d3.forceCenter())
        .force("collide", d3.forceCollide())
        .force("forceY", d3.forceY())


    simulation.force("charge")
        .strength(d => d.size)
    // .distanceMin()
    // .distanceMax()
    simulation.force("collide")
        // .strength(forceProperties.collide.strength * forceProperties.collide.enabled)
        .radius(d => d.radius)
        .iterations(10);
    simulation.force("forceY")
        .strength(0.2)
        .y(1000);
    simulation.force("link")
        // .strength(d => 3)
        .distance(40)
        .id(d => d.id)
        .iterations(10)
        .links(links)


    simulation.stop()

    while (simulation.alpha() > simulation.alphaMin()) {
        simulation.tick()
    }


    // console.log('n', nodes)
    // console.log('l', links)


    // === Generate rudimentary paths

    links.forEach(link => {

        const x1off = link.source.x + (link.source.width / 2) + link.source.leftOffset
        const x2off = link.target.x + (link.source.width / 2) + link.source.leftOffset
        const y1 = link.source.y
        const y2 = link.target.y

        const originBezOffset = [x1off + CURVE_SHARPNESS, y1]
        const targetBezOffset = [x2off - CURVE_SHARPNESS, y2]

        link._path1 = [x1off, y1]
        link._pathB = `${originBezOffset}, ${targetBezOffset}`
        link._path2 = [x2off, y2]
        link._partial = `${[x1off, y1]} C ${originBezOffset}, ${targetBezOffset}, ${[x2off, y2]}`
    })


    // Save final paths, including composites:
    graph.forEachEdge((edgeId, attr) => {

        if (attr.pipe === true) {
        }

        // Save simple paths
        else if (attr.hasPipes !== true) {

            const link = links.find(l => l.id === edgeId)
            graph.mergeEdgeAttributes(edgeId, {
                d: `M ${link._partial}`
            });

        }

        // Combine and save complex paths
        else {

            const pipes = links.filter(l => l.pipe && l.pipeFor === edgeId)
                .sort((a, b) => a.column - b.column)

            const d = pipes.reduce((acc, curr) => {
                acc = !acc ? 'M ' : acc + 'L '
                acc = acc + curr._partial
                return acc
            }, '')

            graph.mergeEdgeAttributes(edgeId, {
                d: d
            });

        }
    })


    // Save coordinate data to graph object
    let minY
    nodes.forEach(node => {
        graph.mergeNodeAttributes(node.id, {
            x: node.x + node.leftOffset,
            y: node.y,
            h: node.size,
            w: node.width
        });
        if (minY === undefined || node.y < minY) minY = node.y
    })
    graph.setAttribute('minY', minY)
}
