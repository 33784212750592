import React, { useEffect, useRef } from "react";

import ScrollContainer from 'react-indiana-drag-scroll'
import { Box, Button, IconButton, Stack } from "@chakra-ui/react"

import { RiZoomInLine, RiZoomOutLine } from 'react-icons/ri'

export default function Viewer(props: any) {

    const {
        height,
        width,
        scrollPadding,
        zoom,
        setZoom,
        setSelectedId,
        selectedId,
    } = props

    const ref = useRef<any>()

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollTo(scrollPadding, scrollPadding)
        }
    }, [ref, scrollPadding]);


    const reset = () => {
        ref.current.scrollTo(scrollPadding, scrollPadding)
        setZoom(100)
    }

    const zoomIn = () => {
        setZoom(zoom + 25)
    }

    const zoomOut = () => {
        setZoom(zoom - 25)
    }

    return <React.Fragment>
        <ScrollContainer
            innerRef={ref}
            style={{
                height: `${height}px`,
                width: `${width}px`,
            }}
        >
            <Box position="relative">

                <Box position="absolute"
                    backgroundColor="white"
                    height={`${height + scrollPadding + scrollPadding}px`}
                    width={`${width + scrollPadding + scrollPadding}px`}
                >&nbsp;</Box>

                <Box position="absolute"
                    left={`${scrollPadding}px`}
                    top={`${scrollPadding}px`}
                >
                    {props.children}
                </Box>




            </Box>

        </ScrollContainer>


        <Stack
            position="absolute"
            top="0"
            left="0"
            direction="row"
            p="3"
        >



        </Stack>

        <Stack
            position="absolute"
            top="0"
            right="0"
            direction="row"
            p="3"
        >


            {selectedId ? (
                <Button
                    size="lg"
                    variant="ghost"
                    onClick={() => setSelectedId(null)}
                >Unfocus</Button>
            ) : null}

            {zoom !== 100 ? (
                <Button
                    size="lg"
                    variant="ghost"
                    onClick={reset}
                >Reset zoom</Button>
            ) : null}


            <IconButton
                aria-label='Zoom out'
                icon={<RiZoomOutLine />}
                size="lg"
                variant="ghost"
                onClick={zoomOut}
            />

            <IconButton
                aria-label='Zoom in'
                icon={<RiZoomInLine />}
                size="lg"
                variant="ghost"
                onClick={zoomIn}
            />


        </Stack>

    </React.Fragment>
}