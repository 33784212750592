// import { DirectedGraph } from 'graphology';

export default function addPipesToGraph(graph: any) {

  graph.edges().forEach((edgeId: string) => {
    const [inId, nodeId] = graph.extremities(edgeId)

    const inCol = graph.getNodeAttribute(inId, 'column')
    const nodeCol = graph.getNodeAttribute(nodeId, 'column')

    // console.log(inId, nodeId, inCol, nodeCol)

    if (nodeCol > inCol + 1) {

      for (let i = inCol + 1; i < nodeCol; i++) {

        // Make pipe node
        const pipeId = `${inId}-${nodeId}-${i}`
        graph.addNode(pipeId, { pipe: true, column: i, pipeFor: edgeId });

        // If first pipe, lay edge back to origin
        if (i - 1 === inCol) {
          graph.addEdge(inId, pipeId, { pipe: true, pipeFor: edgeId, column: i - 0.5 });
        }

        // Else, lay edge back to previous pipe
        else {
          const inPipeId = `${inId}-${nodeId}-${i - 1}`
          graph.addEdge(inPipeId, pipeId, { pipe: true, pipeFor: edgeId, column: i - 0.5 });
        }

        // If last pipe, lay edge to target
        if (i + 1 === nodeCol) {
          graph.addEdge(pipeId, nodeId, { pipe: true, pipeFor: edgeId, column: i + 0.5 });
        }
      }

      graph.mergeEdgeAttributes(edgeId, { hasPipes: true });
      // graph.dropEdge(edgeId)

    }
  })

  // graph.forEachNode(nodeId => {
  //     console.log('x', nodeId, graph.getNodeAttributes(nodeId))
  //   })

  // graph.forEachEdge(edgeId => {
  //   console.log('z', edgeId, graph.extremities(edgeId), graph.getEdgeAttributes(edgeId))
  // })

}
