
const preparse = `
Pottery --> Seafaring
Alphabet --> Map making
Alphabet --> Writing
Alphabet --> Code of laws
Alphabet --> Mathematics
Map making --> Seafaring
Seafaring --> Navigation
Writing --> Literacy
Literacy --> Physics
Navigation --> Physics
Literacy --> The Republic
Code of laws --> Monarchy
Monarchy --> Feudalism
Warrior code --> Feudalism
Feudalism --> Chivalry
Horseback riding --> Chivalry
Warrior code --> Iron working
Broze working --> Iron working
The Republic --> Feudalism
Horseback riding --> The wheel
The wheel --> Engineering
Engineering --> Invention
Literacy --> Invention
Invention --> Gunpowder
Gunpowder --> Leadership
Chivalry --> Leadership
Code of laws --> Literacy

Mathematics --> University
University --> Chemistry
Physics --> Magnetisism
Physics --> Steam Engine
Iron working --> Bridge building
Bronze working --> Currency
Currency --> Trade
Trade --> Banking
Masonry --> Construction
Currency --> Construction
Construction --> Bridge building
Construction --> Engineering
Trade --> Medicine
Ceremonial burial --> Mysticism
Ceremonial burial --> Polytheism
Mysticism --> Astronomy
Mysticism --> Philosophy
Polytheism --> Monotheism
Philosophy --> Monotheism
Philosophy --> Medicine
Astronomy --> Theory of gravity
Monotheism --> Theology
Monotheism --> Fundamentalism
Conscription --> Fundamentalism
Theory of gravity --> Atomic theory
Atomic theory --> Nuclear fission
Nuclear fission --> Nuclear power
Nuclear power --> Fusion power
Nuclear power --> The laser
The laser --> Super conductor
Super conductor --> Fusion power
Fusion power --> Future tech
Super conductor --> Stealth

Medicine --> Sanitation
Sanitation --> Refrigeration
Banking --> Economics
Banking --> Democracy
Democracy --> Conscription
Democracy --> Espionage

Gunpowder --> Explosives
Gunpowder --> Metallurgy
Metallurgy --> Electricity
Metallurgy --> Conscription
Electricity --> Steel
Electricity --> Electronics
Electricity --> Refrigeration
Steel --> Automobile
Steel --> Machine tools
Machine tools --> Miniaturization
Electronics --> Miniaturization
Miniaturization --> Computers
Computers --> Robotics
Robotics --> Stealth
Computers --> Space flight

Leadership --> Tactics
Tactics --> Amphibious warfare
Tactics --> Mobile warfare
Mobile warfare --> Combined arms
Mobile warfare --> Robotics

Chemistry --> Refining
Refining --> Plastics
Refining --> Combustion
Combustion --> Flight
Combustion --> Automobile
Automobile --> Mass production
Automobile --> Mobile warfare
Mass production --> Labor union
Mass production --> Recycling
Mass production --> Computers
Mass production --> Nuclear fission
Recycling --> Environmentalism
Flight --> Radio
Radio --> Advanced flight
Advanced flight --> Rocketry
Rocketry --> Space flight
Space flight --> Environmentalism

Steam engine --> Railroad
Railroad --> Industrialization
Industrialization --> The corporation
Industrialization --> Communism
Communism --> Guerilla warfare
The corporation --> Genetic engineering
Guerilla warfare --> Labor union


`

export default preparse