import React, { useState, useEffect } from 'react';
import { Textarea } from "@chakra-ui/react"
import defaultText from './engine/_input';

export default function MirrorText(props: any) {

    const {
        height,
        reparse,
        initiated,
        setInitiated,
        loadExampleData,
    } = props

    const [text, setText] = useState('')
    const [textToParse, setTextToParse] = useState('')
    const [textParsed, setTextParsed] = useState("")
    const [autosaveTextHandler, setAutosaveTextHandler] = useState<any | null>(null)


    useEffect(() => {
        if (textToParse) {
            if (textToParse.localeCompare(textParsed) === 0) {
                setTextToParse("")
            }
            else {
                reparse(textToParse)
                setTextParsed(textToParse)
                setTextToParse("") 
            }
        }
    }, [textToParse, textParsed, reparse, setInitiated, initiated])


    useEffect(() => {
        if (loadExampleData) {
            setText(defaultText)
            setTextToParse(defaultText)
            setInitiated(true)
        }
    }, [loadExampleData, setInitiated])


    const handleChangeText = (e: any) => {
        setText(e.target.value)
        setInitiated(true)
        triggerAutosaveText()
    }

    const triggerAutosaveText = () => {
        clearTimeout(autosaveTextHandler)
        setAutosaveTextHandler(setTimeout(handleSaveText, 1500))
    }

    const handleSaveText = () => {
        setTextToParse(text)
    }


    return <Textarea 
        value={text}
        onChange={handleChangeText}
        onBlur={handleChangeText}
        size="sm"
        height={height}
        spellCheck="false"
        pt="0px"
        px="6px"
        resize="none"
        fontFamily="monospace"
        fontSize="0.8rem"
        border="0"
        _focus={{
            border: 0
        }}
        />
} 