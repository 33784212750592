import { Box } from "@chakra-ui/react"
import GraphNode from "./GraphNode";
import { DirectedGraph } from "graphology";


const CONTENT_MARGIN_TOP = 50
const CONTENT_MARGIN_LEFT = 20


const COLORS = {
    active: {
        line: "#888",
        arrowhead: "#888",
        brokenLine: "#888",
        brokenArrowhead: "#888",
    },
    passive: {
        line: "#eee",
        arrowhead: "#eee",
    },
    neutral: {
        line: "#bbb",
        arrowhead: "#bbb",
    }
}

export default function DependistGraph(props: {
    graph: DirectedGraph,
    graphState: any,
    toggleNode: (nodeId: string) => void,
    selectedId: string,
    setSelectedId: (id: string) => void,
    viewHeight: number,
    zoom: number
}) {

    const {
        graph,
        graphState,
        toggleNode,
        selectedId,
        setSelectedId,
        zoom,
    } = props

    if (!graph) {
        return null
    }

    const yOffset = -graph.getAttribute('minY') // -Math.min(...combinedNodes.map(n => n.y))

    /** Since there is no zIndex in svgs, elements must be ordered 
     * by visibilty (relevance) in advance.
     */
    const styleAndOrderPaths = () => {

        let ret: any[] = []


        graph.filterEdges((id, attr) => !attr.pipe).forEach(edgeId => {



            let link: any = {
                id: edgeId,
                ...graph.getEdgeAttributes(edgeId)
            }

            const isBroken = graphState[edgeId]?.isBroken
            const isRelevant = graphState[edgeId]?.isRelevant

            link.markerEnd = `url(#${selectedId ? isRelevant ? isBroken ? "arrowheadBroken" : 'arrowhead' : 'arrowheadInactive' : 'arrowheadNeutral'})`
            link.strokeColor = selectedId ? isRelevant ? isBroken ? COLORS.active.brokenLine : COLORS.active.line : COLORS.passive.line : COLORS.neutral.line

            // Draw active paths over passive
            isRelevant ? ret.push(link) : ret.unshift(link)

        })

        return ret
    }
    const styledPaths = styleAndOrderPaths()


    return <Box
        // border="1px solid gold"
        id="origo"
        w={`${100}px`}
        h={`${100}px`}
        position={"relative"}
        transform={`scale(${zoom / 100})`}
        transformOrigin={'top left'}
        top={`${yOffset + CONTENT_MARGIN_TOP}px`}
        left={`${CONTENT_MARGIN_LEFT}px`}
    >


        {/* <DevForcedGraph data={data} /> */}


        <svg height="100%" width="100%"
            style={{
                position: "absolute",
                overflow: "visible",
                top: 0,
            }}>

            <defs>
                <marker id="arrowhead" markerWidth="10" markerHeight="7"
                    refX="9" refY="3.5" orient="auto" fill={COLORS.active.arrowhead}>
                    <polygon points="0 0, 10 3.5, 0 7" />
                </marker>
                <marker id="arrowheadNeutral" markerWidth="10" markerHeight="7"
                    refX="9" refY="3.5" orient="auto" fill={COLORS.neutral.arrowhead}>
                    <polygon points="0 0, 10 3.5, 0 7" />
                </marker>
                <marker id="arrowheadInactive" markerWidth="10" markerHeight="7"
                    refX="9" refY="3.5" orient="auto" fill={COLORS.passive.arrowhead}>
                    <polygon points="0 0, 10 3.5, 0 7" />
                </marker>
                <marker id="arrowheadBroken" markerWidth="10" markerHeight="7"
                    refX="9" refY="3.5" orient="auto" fill={COLORS.active.brokenArrowhead}>
                    <polygon points="0 0, 10 3.5, 0 7" />
                </marker>
            </defs>

            {styledPaths.map(link => (
                <path
                    key={link.id}
                    d={link.d}
                    stroke={link.strokeColor}
                    fill="transparent"
                    strokeWidth="1"
                    markerEnd={link.markerEnd}
                />
            ))}


        </svg>


        {graph.mapNodes((nodeId, attrs) => {


            if (attrs.pipe) {
                return null
            }

            const isSelected = graphState[nodeId]?.isSelected
            const isTrunk = graphState[nodeId]?.isTrunk
            const isBranch = graphState[nodeId]?.isBranch
            const isBroken = graphState[nodeId]?.isBroken
            const isRelevant = isSelected || isTrunk || isBranch
            const isInactive = graphState[nodeId]?.isInactive

            const bgColorContrast = isSelected ? 300 : isRelevant ? 200 : 100

            const bgColorScheme = isSelected ? isBroken ? isInactive ? "red" : "pink" : "teal"
                : isTrunk ? isBroken ? isInactive ? "red" : "pink" : "cyan"
                    : isBranch ? isBroken ? isInactive ? "red" : "orange" : "teal"
                        : "gray"

            const bgColor = `${bgColorScheme}.${bgColorContrast}`
            const color = !isRelevant ? "gray.600" : 'black'

            return <GraphNode
                key={nodeId}
                nodeId={nodeId}
                {...attrs}
                bgColorScheme={bgColorScheme}
                bgColor={bgColor}
                color={color}
                isRelevant={isRelevant}
                isInactive={isInactive}
                toggleNode={toggleNode}
                setSelectedId={setSelectedId}
            />


        })}

    </Box>


}
