import { Text, Flex } from "@chakra-ui/react"
import DepCheckbox from './DepCheckbox';


export default function GraphNode(props: any) {

    const {
        nodeId,
        bgColor,
        setSelectedId,
        isRelevant,
        isInactive,
        bgColorScheme,
        toggleNode,
        color,
        h,
        w,
        x,
        y,
    } = props


    const handleClick = (e: any) => {
        e.stopPropagation()
        setSelectedId(nodeId)
    }

    return (
        <Flex

            h={`${h}px`}
            w={`${w}px`}
            left={`${x}px`}
            top={`${y}px`}

            onClick={handleClick}
            border={`'px solid transparent'`}
            boxShadow="md"
            rounded="5px"
            align="center"
            bg={bgColor}
            px="1"
            position="absolute"
            overflow={'hidden'}
            whiteSpace={'nowrap'}
            textOverflow={'ellipsis'}
            cursor={'pointer'}
            transform={`translateX(-50%)translateY(-50%)`}
        >
            <Flex
                onClick={(e) => e.stopPropagation()}
                mr="1"
            >
                {isRelevant ?
                    <DepCheckbox size="sm" mr="1"
                        isChecked={!isInactive}
                        colorScheme={bgColorScheme}
                        colorContrast={400}
                        onChange={() => toggleNode(nodeId)} />
                    :
                    <DepCheckbox size="sm" mr="1"
                        colorScheme={bgColorScheme}
                        colorContrast="100"
                        isDisabled={true} />
                }

            </Flex>

            {/* {isBroken ? <Text fontSize="xs" color={"red"}>B&nbsp;</Text> : null} */}
            <Text fontSize="xs" color={color}>{nodeId}</Text>
        </Flex>
    )

}