import React from "react";
import { Box } from "@chakra-ui/react"
import Dependist from './main/Dependist';
import { withSize } from 'react-sizeme'

function AppFrame(props: { size: { height: number, width: number }}) {

    const { height, width } = props.size

    return <Box
        width="100vw"
        height="100vh"
    >

        <Dependist height={height} width={width} />

    </Box>
}


export default withSize({ monitorHeight: true })(React.memo(AppFrame));