
/** Transform input text to json objects, without any validation
 * or processing.
 */
export default function rawParseText(input: string) {

  let raw = []

  const edgesExp = /^\s*(?<from>.*?\w)\s*-->\s*(?<to>.*\w)\s*$/gm
  const edgesMatches = input.matchAll(edgesExp);

  // @ts-ignore
  for (const match of edgesMatches) {
    const g = match.groups || {}
    raw.push([g.from, g.to])
  }

  return raw

}