import { chakra, useCheckbox, Flex, Box } from '@chakra-ui/react'


export default function CustomCheckbox(props: any) {
    const { state, getCheckboxProps, getInputProps } = useCheckbox(props)

    const bgColor = `${props.colorScheme}.100`
    const color = `${props.colorScheme}.${props.colorContrast}`

    // console.log('htmlPro', htmlProps)

    return (
        <chakra.label
            cursor='pointer'
            // {...htmlProps}
        >
            <input {...getInputProps()} hidden />
            <Flex
                alignItems='center'
                justifyContent='center'
                bg={bgColor}
                border='2px solid'
                borderColor={color}
                borderRadius={"35%"}
                w={4}
                h={4}
                {...getCheckboxProps()}
            >
                {state.isChecked && <Box
                    w={2}
                    h={2}
                    bg={color}
                    borderRadius={"35%"}
                />}
            </Flex>
        </chakra.label>
    )
}

